import { Box, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

import SiderSubDrawer from '@components/atoms/drawer/SiderSubDrawer';
import UniversityMasterDegreeContentListing from '@components/molecules/universityMasterDegreeContentListing';

export type MasterDegreeListSubDrawerProps = {
  isOpen: boolean;
  onClose?(): void;
};

export const UniversityMasterDegreeListSubDrawer = (props: MasterDegreeListSubDrawerProps) => {
  const { t } = useTranslation('common');

  const { isOpen, onClose } = props;

  return (
    <SiderSubDrawer isOpen={isOpen} onClose={onClose}>
      <Box p={2} sx={{ overflowY: 'scroll', scrollBehavior: 'smooth' }}>
        <Typography color="#00000061" pt={1} pb={2} fontSize="18px">
          {t('menu.master_degree')}
        </Typography>
        <UniversityMasterDegreeContentListing />
      </Box>
    </SiderSubDrawer>
  );
};

export default UniversityMasterDegreeListSubDrawer;
