import { MenuItem } from '@mui/material';
import { useTranslation } from 'next-i18next';

import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';

export type BackMainMenuProps = {
  onClick(): void;
};

const BackMainMenu = ({ onClick }: BackMainMenuProps) => {
  const { t } = useTranslation('common');
  return (
    <MenuItem className="back-menu" onClick={onClick}>
      <FontAwesomeIcon icon="fa-light fa-chevron-left" style={{ fontSize: '12px', marginRight: '1rem' }} />
      {t('back_main_menu')}
    </MenuItem>
  );
};

export default BackMainMenu;
