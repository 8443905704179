import { withTheme } from '@mui/styles';
import styled from 'styled-components';

const UserMenuProfileStyle = withTheme(styled.div`
  .profile-container {
    width: 170px;
  }
  .full-name {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
  }
  .email {
    font-size: 18px;
    font-family: Lanna;
    color: #676767;
    line-height: 1;
  }
  .MuiAvatar-root {
    width: 50px;
    height: 50px;
    margin-right: 16px;
    background-color: #f5f6f7;
    color: ${(props: any) => props.theme.palette.primary.main};
    border: solid 1px #cccccc;
    svg {
      font-size: 20px;
    }
  }
  ${(props: any) => props.theme.breakpoints.down('lg')} {
    .profile-container {
      width: 100%;
    }
  }
`);

export default UserMenuProfileStyle;
