import { useMemo } from 'react';

import { SWRConfiguration } from 'swr';

import { Nullable } from '@common/type/CommonTypes';
import { getToken } from '@helpers/auth';
import { Role, UserType } from '@helpers/constants';
import { useRequest } from '@helpers/use';
import { swrImmutableOptions } from '@helpers/utils';
import { IUserProfile, UserProfile } from '@interfaces/user';
import userAPI, { USER_END_POINT } from 'src/api/user';

export const useProfile = (options?: SWRConfiguration) => {
  const hasAccessToken = getToken();
  const { data, isLoading, isValidating, response, mutate } = useRequest<IUserProfile>(
    hasAccessToken ? USER_END_POINT.USER_ME : null,
    userAPI.fetchProfile,
    { ...options, ...swrImmutableOptions },
  );

  const profile: Nullable<UserProfile> = useMemo(() => {
    if (!data) {
      return null;
    }

    return {
      id: data.id,
      hashId: data.hashId,
      email: data.email,
      hashEmail: data.hashEmail,
      slug: data.slug,
      firstname: data.firstname,
      lastname: data.lastname,
      avatar: data.avatar,
      isOfficialProfile: data.is_official_profile,
      officialFirstname: data.official_firstname,
      officialLastname: data.official_lastname,
      officialPrefixTh: data.official_prefix_th,
      officialPrefixEn: data.official_prefix_en,
      officialFirstnameEn: data.official_firstname_en,
      officialLastnameEn: data.official_lastname_en,
      dialCode: data.dialCode,
      role: data.role as UserType,
      roles: data.roles.map((role) => ({
        id: role.id,
        roleId: role.role_id,
        name: role.name as Role,
      })),
      notificationCount: data.notification_count,
      isNotificationSeen: data.is_notification_seen,
      latestNotificationId: data.latest_notification_id,
      loyaltyPoints: data.loyalty_points,
      isLearnAcademic: data.is_learn_academic,
      isEligibleForCouponsNewUser: data.is_eligible_for_coupons_new_user,
      uid: data.uid,
      citizenId: data.citizen_id,
      isEmailValid: data.is_email_valid,
      isVerifyWithEkyc: data.isVerifyWithEkyc,
      emailValidatedResultCode: data.email_validated_result_code,
      lastEmailValidated_at: data.last_email_validated_at,
      cpdCitizenId: data.cpd_citizen_id,
      isCPDUser: data.is_cpd,
      isAffiliatePartner: data.is_affiliate_partner,
      birthday: data.birthday,
      phone: data.phone,
      skilllaneAcademicIds: data.skilllaneAcademicIds,
      passportNumber: data.passport_number,
    };
  }, [data]);

  return {
    profile,
    response,
    isLoading,
    isValidating,
    mutate,
  };
};
