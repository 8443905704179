import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export type DrawerProps = {
  isScroll: boolean;
  isGuest: boolean;
};

export const DrawerStyle = styled(Drawer)<DrawerProps>`
  .MuiDrawer-paper {
    width: 250px;
    overflow: hidden;
    background-color: transparent;
    box-shadow: none;
  }
  .menu-container {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    overflow: hidden;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  .user-container {
    box-shadow: ${(props: any) => (props.isScroll ? '0 3px 6px 0 rgba(0, 0, 0, 0.16)' : 'none')};
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .user-profile-container {
    box-shadow: ${(props: any) => (props.isScroll ? '0 3px 6px 0 rgba(0, 0, 0, 0.16)' : 'none')};
    ${(props: any) => props.isScroll || 'border: solid 1px #ebebeb'};
  }
  .MuiList-root {
    overflow-y: auto;
  }
  .close-button-container {
    position: fixed;
    left: 250px;
  }
  .close-button {
    height: 50px;
    width: 50px;
    top: 16px;
    left: 10px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    &:hover {
      background-color: white;
    }
  }
  .text-header {
    color: #8e8e8e;
    font-size: 18px;
    font-weight: bold;
  }

  .image-profile {
    ${(props: any) => (props.isScroll ? 'display: none' : 'display: flex')};
  }

  .back-menu {
    background: #f5f5f5;
    font-size: 18px;
    margin-bottom: -4px;
  }
`;

export default DrawerStyle;
