import { useState, useEffect } from 'react';
import type { ReactNode } from 'react';

import { useSpring, animated } from '@react-spring/web';

import BackMainMenu from '@components/atoms/drawer/BackMainMenu';

export type SiderSubDrawerProps = {
  isOpen?: boolean;
  children: ReactNode
  onClose?(): void;
};

const SiderSubDrawer = (props: SiderSubDrawerProps) => {
  const { children, onClose } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const styles = useSpring({
    left: isOpen ? 0 : 250,
    top: 0,
    height: '100vh',
    width: 250,
    zIndex: 0,
    config: { duration: 200 },
  });

  const handleClose = () => {
    onClose?.();
  };

  useEffect(() => {
    setIsOpen(props.isOpen || false);
  }, [props.isOpen]);

  return (
    <animated.div style={{ ...styles, position: 'absolute' }}>
      <div className="menu-container">
        <BackMainMenu onClick={handleClose} />
        {children}
      </div>
    </animated.div>
  );
};

export default SiderSubDrawer;
