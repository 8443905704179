import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';

import { MenuItemLink, MenuItemLinkProps } from '@components/atoms/menuLinkList';
import { useNotification } from '@hooks/useNotification';
import useUserMenuListing from '@hooks/useUserMenuListing';

const UserMenuDrawerContent = () => {
  const { isNotificationSeen, handleOpenNotificationModal } = useNotification();
  const { menuList } = useUserMenuListing({
    excluded: [''],
  });

  const { t } = useTranslation();

  return (
    <>
      {menuList.map((menuGroup) => {
        const { isHideTitle = false, menuItems } = menuGroup;
        const hasMenuItem = menuItems.length === 0;
        if (hasMenuItem) {
          return null;
        }

        return (
          <>
            {isHideTitle === false ? (
              <Typography fontWeight="bold" fontSize={18} color="#00000061" px={2} py={1}>
                {menuGroup.label}
              </Typography>
            ) : null}
            {menuItems.map((menuItem) => {
              const menuItemProps: MenuItemLinkProps = {
                name: menuItem.label,
                link: menuItem.url ?? '',
              };

              if (menuItem.key === 'my-notifications') {
                menuItemProps.isAlert = isNotificationSeen === false;
                menuItemProps.onClick = handleOpenNotificationModal;
              }

              return <MenuItemLink key={menuItem.key} {...menuItemProps} />;
            })}
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
          </>
        );
      })}
    </>
  );
};

export default UserMenuDrawerContent;
