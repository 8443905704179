import Router from 'next/router';
import { MouseEventHandler, useState, useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import { useTranslation } from 'next-i18next';

import Button from '@components/atoms/button';
import BackMainMenu from '@components/atoms/drawer/BackMainMenu';
import { MenuItemLink, MenuLinkList } from '@components/atoms/menuLinkList';
import UniversityMasterDegreeListSubDrawer from '@components/molecules/universityMasterDegreeListSubDrawer';
import DrawerStyle from '@components/molecules/userMenuDrawer/style';
import UserMenuDrawerContent from '@components/molecules/userMenuDrawerContent';
import UserMenuProfile from '@components/molecules/userMenuProfile';
import { login, register } from '@helpers/auth';
import { BOTTOM_MENU, GENERAL_MENU, ROUTES } from '@helpers/constants';
import { useAppData } from '@helpers/use';
import { ICategory } from 'src/interfaces';
import { IUserProfile } from 'src/interfaces/user';

type UserMenuDrawerProps = {
  isOpen: boolean;
  onClose: MouseEventHandler<Element>;
  profile: IUserProfile | undefined;
  isNotificationSeen: boolean;
  seeNotification: () => void;
  isLoading: boolean;
};

const UserMenuDrawer = (props: UserMenuDrawerProps) => {
  const { isOpen, onClose, profile, isLoading } = props;
  const { t } = useTranslation('common');
  const { appData } = useAppData();
  const categories = appData?.categories || [];

  const [scrollTopPosition, setScrollTopPosition] = useState(0);
  const [category, setCategory] = useState<ICategory | null>(null);
  const [isOpenMasterDegreeSubDrawer, setIsOpenMasterDegreeSubDrawer] = useState<boolean>(false);

  const handleOnScroll = (e: any) => {
    setScrollTopPosition(e.target.scrollTop);
  };

  const handleOpenMasterDegreeDrawer = () => {
    setIsOpenMasterDegreeSubDrawer(true);
  };

  const handleCloseMasterDegreeSubDrawer = () => {
    setIsOpenMasterDegreeSubDrawer(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setCategory(null);
        handleCloseMasterDegreeSubDrawer();
      }, 200);
    }
  }, [isOpen]);

  let MenuComponent;

  if (isLoading) {
    MenuComponent = (
      <Box p={2}>
        <Skeleton animation="wave" height={40} />
        <Skeleton animation="wave" height={40} />
      </Box>
    );
  } else if (profile) {
    MenuComponent = (
      <Box className="user-profile-container">
        <UserMenuProfile isMobile profile={profile} />
      </Box>
    );
  } else {
    MenuComponent = (
      <div className="user-container">
        <ListItem key="register">
          <Button onClick={register} className="register-button" variant="contained" color="primary" fullWidth>
            {t('register')}
          </Button>
        </ListItem>
        <ListItem key="login">
          <Button onClick={() => login()} className="login-button" variant="outlined" color="primary" fullWidth>
            {t('login')}
          </Button>
        </ListItem>
      </div>
    );
  }

  return (
    <DrawerStyle
      isScroll={scrollTopPosition > 10}
      isGuest={!profile}
      anchor="left"
      open={isOpen}
      onClose={onClose}
      data-testid="mobile_userMenu"
    >
      <Box height="100%" className="close-button-container" onClick={onClose}>
        <IconButton color="primary" className="close-button">
          <CloseIcon />
        </IconButton>
      </Box>
      <div className="menu-container">
        {MenuComponent}
        <List onScroll={handleOnScroll}>
          {!!profile && <UserMenuDrawerContent />}
          <ListItem key="general">
            <Typography className="text-header">{t('general_menu')}</Typography>
          </ListItem>
          {GENERAL_MENU.map((menu) => {
            if (menu.name === 'master_degree') {
              return <MenuItemLink key={menu.name} {...menu} onClick={handleOpenMasterDegreeDrawer} isShowSuffixIcon />;
            }

            return <MenuItemLink key={menu.name} {...menu} />;
          })}
          <Box py={2}>
            <Divider />
          </Box>
          <ListItem key="category">
            <Typography className="text-header">{t('category')}</Typography>
          </ListItem>
          <MenuLinkList list={categories} setCategory={setCategory} isDisableHyperlink />
          <Box py={2}>
            <Divider />
          </Box>
          <Box pb={2}>
            <MenuLinkList list={BOTTOM_MENU} />
          </Box>
          {!!profile && (
            <ListItem key="login">
              <Button variant="outlined" color="primary" fullWidth onClick={() => Router.push(ROUTES.LOGOUT)}>
                {t('logout')}
              </Button>
            </ListItem>
          )}
        </List>
      </div>
      <SubcategoryMenu category={category!} />
      <UniversityMasterDegreeListSubDrawer
        isOpen={isOpenMasterDegreeSubDrawer}
        onClose={handleCloseMasterDegreeSubDrawer}
      />
    </DrawerStyle>
  );
};

/** @deprecated should be 1 component per file */
const SubcategoryMenu = ({ category }: { category?: ICategory }) => {
  const { t } = useTranslation('common');
  const [openSubcategory, setOpenSubcategory] = useState<boolean>(!!category);
  const styles = useSpring({
    left: openSubcategory ? 0 : 250,
    top: 0,
    height: '100vh',
    width: 250,
    zIndex: 0,
    config: { duration: 200 },
  });

  useEffect(() => {
    if (category) {
      setOpenSubcategory(!!category);
    }
  }, [category]);

  return (
    <animated.div style={{ ...styles, position: 'absolute' }}>
      <div className="menu-container">
        <BackMainMenu
          onClick={() => {
            setOpenSubcategory(false);
          }}
        />
        <List>
          <ListItem key="category">
            <Typography className="text-header" style={{ color: 'black' }}>
              {t('main_category')}
            </Typography>
          </ListItem>
          <MenuItemLink {...category!} isSubcategoryMenu />
          <Box py={1}>
            <Divider />
          </Box>
          <ListItem key="subcategory">
            <Typography className="text-header">{t('subcategory')}</Typography>
          </ListItem>
          <MenuLinkList list={category?.children} isSubcategoryMenu />
        </List>
      </div>
    </animated.div>
  );
};

export default UserMenuDrawer;
